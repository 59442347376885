.featuredTitle {
    @apply laptop-l:text-[50px] tablet:text-[30px] laptop:text-[40px]  mobile-l:text-[32px] text-[20px]
}
.catText {
    @apply break:text-[16px] tablet:text-[10px] mobile-l:text-[15px] text-[12px]
}

.infoText {
    @apply laptop-l:text-[20px] tablet:text-[15px] mobile-l:text-[15px] text-[11px] whitespace-nowrap
}

.buttonText {
    @apply whitespace-nowrap tablet:text-[16px] text-[12px] mobile-l:text-[17px]
}