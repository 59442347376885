@keyframes custom-bounce {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-10px);
	}
}
.customBounce1 {
	animation: custom-bounce 2s infinite;
}

.customBounce2 {
	animation: custom-bounce 2s infinite;
	rotate:30.33deg
}
