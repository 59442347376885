@tailwind base;
@tailwind components;
@tailwind utilities;


.font-smoother {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smooth: never;
}

.ql-editor {
	padding: 0 !important;
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: scale(0.95);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
		transform: scale(1);
	}
	to {
		opacity: 0;
		transform: scale(0.95);
	}
}

.fade-in {
	animation: fadeIn 0.3s ease-out forwards;
}

.fade-out {
	animation: fadeOut 0.3s ease-out forwards;
}

@font-face {
	font-family: "RobotoFlex";
	src: url("../public//fonts/Roboto-Flex.ttf");
}

@font-face {
	font-family: "Roboto";
	src: url("../public//fonts/Roboto-Regular.ttf");
}
@font-face {
	font-family: "Roboto-med";
	src: url("../public//fonts/Roboto-Medium.ttf");
}
@font-face {
	font-family: "Roboto-light";
	src: url("../public//fonts/Roboto-Light.ttf");
}
@font-face {
	font-family: "Roboto-bold";
	src: url("../public//fonts/Roboto-Bold.ttf");
}
@font-face {
	font-family: "Roboto-black";
	src: url("../public//fonts/Roboto-Black.ttf");
}
@font-face {
	font-family: "Poppins";
	src: url("../public//fonts/Poppins-Regular.ttf");
}
@font-face {
	font-family: "Poppins-eb";
	src: url("../public//fonts/Poppins-ExtraBold.ttf");
}
@font-face {
	font-family: "Poppins-sb";
	src: url("../public//fonts/Poppins-SemiBold.ttf");
}

@font-face {
	font-family: "Poppins-bold";
	src: url("../public//fonts/Poppins-Bold.ttf");
}

@font-face {
	font-family: "Poppins-italic";
	src: url("../public//fonts/Poppins-Italic.ttf");
}

@font-face {
	font-family: "Poppins-medium";
	src: url("../public//fonts/Poppins-Medium.ttf");
}

@font-face {
	font-family: "Poppins-light";
	src: url("../public//fonts/Poppins-Light.ttf");
}

@font-face {
	font-family: "Poppins-black";
	src: url("../public//fonts/Poppins-Black.ttf");
}

@font-face {
	font-family: "Poppins-ebi";
	src: url("../public//fonts/Poppins-ExtraBoldItalic.ttf");
}

@font-face {
	font-family: "Poppins-blacki";
	src: url("../public//fonts/Poppins-BlackItalic.ttf");
}

.widthClass {
	width: 1512px;
}

#header {
	transition: transform 0.3s;
}
.home-header{
	width: calc(100% - var(--scrollbar-width)); /* Dynamically adjust for scrollbar */
}




:root {
	--scrollbar-width:  0.82%; /* Adjust this based on your scrollbar width */
}
/* When scrolling down, move the header out of view */
.scroll-down {
	transform: translateY(-100%);
}

/* When scrolling up, bring the header back into view */
.scroll-up {
	transform: translateY(0);
}

@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap");

.section {
	@apply container relative flex items-center justify-center w-full mx-auto opacity-0;
}

body {
	margin: 0;
	overflow: visible;
	font-family: "Red Hat Display", sans-serif;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}
 .scroller {
	overflow-y: scroll
 }

.wrapper {
	width: 100vw;
	max-width: 3105px;
	margin-inline: auto;
	position: relative;
	height: 56px;
	overflow: clip;
}

@keyframes scrollLeft {
	to {
		left: -621px;
	}
}

.bg-footer-pattern {
	background-image: url("../public/assets/Footer/bg.svg");
	background-repeat: repeat-x;
	background-position: center bottom;
	background-size: 3000.9px auto;
}

/* Good bad cards */
#card {
	transform-style: preserve-3d;
	transform-origin: center;
	transition: transform 0.6s ease-out;
}

#card2 {
	transform-style: preserve-3d;
	transform-origin: center;
	transition: transform 1.4s ease-out;
}

.backface-hidden {
	backface-visibility: hidden;
}

.rotate-y-180 {
	transform: rotateY(180deg);
}
